<template>
  <div>
    <div class="header fixed-top" v-if="!$route.path.includes('/management')">
      <img src="@/assets/img/logo.png" style="width:100px">
      <el-menu :default-active="currentIndex" mode="horizontal" @select="handleSelect"  >
        <el-menu-item index="index">首页</el-menu-item>
        <el-submenu index="CompanyProfile" >
          <template slot="title" ><span @click="hselect('about','CompanyProfile')">关于我们</span></template>
          <el-menu-item  index="CompanyProfile" class="item-font">  <i class="el-icon-office-building" style="font-size: 12px"></i>公司简介</el-menu-item>
          <el-menu-item  index="developmentHistory" class="item-font"> <i class="el-icon-finished" style="font-size: 12px"></i>发展历程</el-menu-item>
          <el-menu-item  index="HonorQualification"  class="item-font"> <i class="el-icon-trophy" style="font-size: 12px"></i>荣誉资质</el-menu-item>
        </el-submenu>
        <el-submenu index="SchedulingManagement" >
          <template slot="title"><span @click="hselect('products','SchedulingManagement')">产品体系</span></template>
          <el-menu-item  index="SchedulingManagement"  class="item-font"> <i class="el-icon-cpu" style="font-size: 12px"></i>调度管理与辅助决策</el-menu-item>
          <el-menu-item  index="PowerAnalysis"  class="item-font"> <i class="el-icon-connection" style="font-size: 12px"></i>电网运行分析</el-menu-item>
          <el-menu-item  index="energyManagement"  class="item-font"> <i class="el-icon-sunrise-1" style="font-size: 12px"></i>新能源运行管理</el-menu-item>
          <el-menu-item  index="ai"  class="item-font"> <i class="el-icon-thumb" style="font-size: 12px"></i>电网人工智能</el-menu-item>
          <el-menu-item  index="UseCase"  class="item-font"> <i class="el-icon-document-copy" style="font-size: 12px"></i>使用案例</el-menu-item>
        </el-submenu>
        <el-submenu index="whConsulting">
          <template slot="title"><span @click="hselect('news','whConsulting')">新闻动态</span></template>
          <el-menu-item index="whConsulting"  class="item-font"> <i class="el-icon-headset" style="font-size: 12px"></i>网华资讯</el-menu-item>
        </el-submenu>
        <el-submenu index="contact">
          <template slot="title"><span @click="hselect('contact','contact')">联系我们</span></template>
          <el-menu-item index="tRecruitment" class="item-font" @click="hselect('contact','tRecruitment')"> <i class="el-icon-s-custom" style="font-size: 12px"></i>人才招聘</el-menu-item>
        </el-submenu>
      </el-menu>
      <el-button type="primary" size="mini" class="btncolor" @click="link">联系我们</el-button>
    </div>
    <div style="height: 40px;"  v-if="!$route.path.includes('/management')"></div>
    <VScaleScreen :bodyOverflowHidden="false" :fullScreen="true">
      <router-view></router-view>
      <!-- 底栏 -->
      <Footer v-if="!$route.path.includes('/management')"></Footer>
    </VScaleScreen>
  </div>
</template>

<script>
import  Footer  from '@/components/Footer.vue';

export default {
  name: 'Root',
  data() {
    return {
      currentIndex:'index',
    };
  },
  components:{Footer},

  methods: {
    handleSelect(key,keyPath) {
      if(keyPath[1]){
        this.$router.push('/'+keyPath[0]+'#'+keyPath[1])
      }
      else{
        this.$router.push('/'+keyPath[0])
      }
    },
    hselect(key,keyPath) {
      this.$router.push('/'+keyPath);
      this.currentIndex = keyPath;
    },
    link(){
      this.$router.push('/login');
      this.currentIndex = 'login';
    }
  },
  watch: {
    $route(newVal, oldVal){
      let names = newVal.fullPath.slice(1);
      this.currentIndex = names;
    }
  },
  mounted() {
    let key  = window.location.hash.replace('#/','');
    this.currentIndex = key;
  }
}
</script>

<style lang="scss">
.fixed-top {
  position: fixed; /* 固定位置 */
  top: 0; /* 距离顶部0像素 */
  width: 100%; /* 导航栏宽度 */
  z-index: 1000; /* 确保导航栏在页面的最上层 */
  background-color: #f4f5f7;
}
.screen-box{
  background-color: #f4f5f7 !important;
  width: 100% !important;
  .screen-wrapper {
    overflow-y: auto !important;
    background-color: #fff;
  }
}
.item-font{
  font-size: 11px;
}
.header{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  .el-menu {
    background-color: #f4f5f7;
    height: 40px;
    .el-menu-item{
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }
    .el-submenu__title{
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }
  }
  .btncolor{
    background-color:#38A4AE;
    &:hover{
      background-color:#5ac2cc;
    }
  }
  .el-menu--horizontal{
    border: none;
  }
}
::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background-color: #38A4AE;
}
.el-submenu:hover {
  transform: translate(0, -3px);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  background-color: #dbdfe3;
}
.el-menu--popup {
  min-width: 100px !important;
}
</style>